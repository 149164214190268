import { template as template_bdc7f710da3948e2846d5488efc3718b } from "@ember/template-compiler";
const FKText = template_bdc7f710da3948e2846d5488efc3718b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
