import { template as template_3faea38b5f814a20ab16c8a21630b25c } from "@ember/template-compiler";
import NotificationsTracking from "discourse/components/notifications-tracking";
import { i18n } from "discourse-i18n";
const CategoryNotificationsTracking = template_3faea38b5f814a20ab16c8a21630b25c(`
  <NotificationsTracking
    @onChange={{@onChange}}
    @levelId={{@levelId}}
    @showCaret={{@showCaret}}
    @showFullTitle={{@showFullTitle}}
    @prefix="category.notifications"
    @title={{i18n "category.notifications.title"}}
    class="category-notifications-tracking"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CategoryNotificationsTracking;
