import { template as template_011a44ecc2e64f48bef2c4a4e55a386c } from "@ember/template-compiler";
const FKControlMenuContainer = template_011a44ecc2e64f48bef2c4a4e55a386c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
